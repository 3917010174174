<template>
  <div class="container">
    <div class="welcome-card">
      <h1>Welcome to <span class="brand-highlight">TYM InsightPro</span></h1>
      <p>Your trusted tool for advanced analytics and insights.</p>
    </div>
  </div>
</template>


<script>
import UserService from "../services/user.service";

export default {
  name: "Home",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    UserService.getPublicContent().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>



<style scoped>
/* Main Container */
.container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center alignment vertically */
  height: 95vh;
  width: calc(100% - 220px); /* Deduct sidebar width from total width */
  margin-left: 220px; /* Offset content to the right of the sidebar */
  background: linear-gradient(135deg, #fff(180, 20%, 99%),  #fff); /* Professional gradient */
  font-family: 'Roboto', sans-serif; /* Clean, modern font */
  color: #fff;
}


/* Welcome Card Styling */
.welcome-card {
  text-align: center;
  padding: 30px 25px;
  background: linear-gradient(145deg, #ffffff, #f4f6f9); /* Subtle gradient for depth */
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(14, 13, 13, 0.2); /* Clean shadow for focus */
  max-width: 600px; /* Smaller width for focus */
  width: 70%;
  transition: transform 2.9s ease, box-shadow 0.9s ease;
}

.welcome-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3); /* Elevated hover effect */
}

/* Welcome Card Title */
.welcome-card h1 {
  font-size: 3rem;
  font-weight: 700;
  color: #333; /* Neutral text for professionalism */
  margin-bottom: 15px;
  line-height: 1.4;
}

.welcome-card h1 .brand-highlight {
  color: #007bff; /* Accent color for branding */
  font-style: italic;
}

/* Welcome Card Subtitle */
.welcome-card p {
  font-size: 1.5rem;
  color: #110101;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .welcome-card {
    padding: 20px 15px;
  }

  .welcome-card h1 {
    font-size: 1.6rem;
  }

  .welcome-card p {
    font-size: 0.9rem;
  }
}
</style>
